import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/FooterStyles.css";
import logo1 from "../images/Selected/footerDesign2.png";
import logo2 from "../images/Selected/footerDesign1.png";
import mainLogo from "../images/siteLogo/logo.png";
import {
  GrFacebookOption,
  GrTwitter,
  GrInstagram,
  GrYoutube,
  GrPinterest,
} from "react-icons/gr";

function Footer() {
  return (
    <div className="footer-bar-sec">
      <div className="footer-bar row">
        <div className="footer-part col-2">
          <img src={logo1} alt="" />
        </div>
        <div className="footer-part col-8">
          <div className="row footer-sec">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="footer-desc">
                <p>
                  We are surrounded by 24 acres of beautifully manicured
                  gardens, verdant forests and woodland rich with birdlife, all
                  overlooking the shores of rippling Kandy, Sri Lanka, Your
                  journey to Aviyana - from Colombo to kandy, provides an
                  unforgettable bird's-eye perspective, a thrilling and
                  entertaining experience Revel in the unique and spectacular
                  opportunity of flying over and admiring several kilometers our
                  amazing shores and pristine nature with our private Helicopter
                  ride.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 justify-content-center">
              <div className="main-logo">
                <img src={mainLogo} alt="" />
              </div>
              <div className="footer-social-links">
                <div className="social-icon-fb">
                  <a href="" className="">
                    <GrFacebookOption className="social-icon" />
                  </a>
                </div>
                <div className="social-icon-fb">
                  <a href="" className="">
                    <GrTwitter className="social-icon" />
                  </a>
                </div>
                <div className="social-icon-fb">
                  <a href="" className="">
                    <GrInstagram className="social-icon" />
                  </a>
                </div>
                <div className="social-icon-fb">
                  <a href="" className="">
                    <GrYoutube className="social-icon" />
                  </a>
                </div>
                <div className="social-icon-fb">
                  <a href="" className="">
                    <GrPinterest className="social-icon" />
                  </a>
                </div>
              </div>

              <div className="row ">
                <div className="col">
                  {/* <button className="footer-nav-button">
                    The Magical Touch of Luxury
                    <svg
                      viewBox="0 0 12 8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="footer__hotel-chevron"
                    >
                      <path
                        d="M11 0L6 7 1 0"
                        stroke="#000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button> */}
                  <p className="copyright-text">
                    ©2022 AVIYANA Hotels. All Rights Reserved <br /> Design &
                    Develop By Mavs Digital
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="row ">
                <div className="col footer-link-sec">
                  <div className="footer-link d-flex flex-column">
                    <a className="Gold " href="">
                      Know Sri Lanka
                    </a>
                    <a href="">Nature Trails</a>
                    <a href="">CSR & Sustainability</a>
                    <a href="">Covid-19 update</a>
                    <a href="">Terms & Conditions</a>
                    <a href="">Privacy Statement</a>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-link d-flex flex-column">
                    <a href="">Careers</a>
                    <a href="">Legal</a>
                    <a href="">Contact us</a>
                    <a href="">Blog</a>
                    <a href="">Media</a>
                    <a href="">Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-part col-2">
          <img src={logo2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
